// @ts-ignore
import wx from 'weixin-js-sdk';
import { request } from './request';

// 定义一个类型

interface WxConfig {
  appId: string;
  timestamp: number;
  nonceStr: string;
  signature: string;
}

const init = () => {
  request
    .get<WxConfig>(`/auth/config?wxurl=${window.location.href.split('#')[0]}`)
    .then((data) => {
      wx.config({
        debug: false,
        appId: data.appId,
        timestamp: data.timestamp,
        nonceStr: data.nonceStr,
        signature: data.signature,
        jsApiList: ['scanQRCode'],
      });
    });
};
export { wx, init };
