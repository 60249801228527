import { Grid, Mask, Card } from 'antd-mobile';
import { Link } from 'react-router-dom';

import jdcx from './../icon/jdcx.svg';
import zs from './../icon/zs.svg';
import zn from './../icon/zn.svg';
import xw from './../icon/xw.svg';
import map from './../icon/map.svg';
import zm from './../icon/zm.svg';
import sys from './../icon/sys.svg';
import jt from './../icon/jt.svg';
import zmhy from './../icon/zmhy.svg';
import chapter from './../icon/chapter.svg';
import yy from './../icon/yy.svg';
import user from './../icon/user.svg';
import query from './../icon/query.svg';
import rate from './../icon/rate.svg';
import dzzz from './../icon/dzzz.svg';
import file from './../icon/file.svg';
import lc from './../icon/lc.svg';
import address from './../icon/address.svg';
import cxsq from './../icon/cxsq.svg';
import wechat_pay from './../icon/wechat_pay.svg';
import bill from './../icon/bill.svg';
import point from './../icon/point.svg';
import notice from './../icon/notice.svg';
import './../css/Menu.css';
import React, { useEffect, useState } from 'react';
import { request } from '../util/request';
import { UserResponse } from './PersonalCenter';

interface Menu {
  name: string;
  path: string;
  icon: keyof typeof iconMap | string;
  type: string;
}

const iconMap = {
  jdcx: jdcx,
  zs: zs,
  zn: zn,
  xw: xw,
  map: map,
  zm: zm,
  sys: sys,
  jt: jt,
  zmhy: zmhy,
  chapter: chapter,
  yy: yy,
  user: user,
  query: query,
  rate: rate,
  dzzz: dzzz,
  file: file,
  lc: lc,
  address: address,
  cxsq: cxsq,
  wechat_pay: wechat_pay,
  bill: bill,
  point: point,
  notice: notice,
};
export default function Menu() {
  const [visible, setVisible] = useState(false);
  const [menu1, setMenu1] = useState<Menu[]>([]);
  const [menu2, setMenu2] = useState<Menu[]>([]);
  const [userData, setUserData] = useState<UserResponse>();
  const loadUser = () => {
    request.get<UserResponse>('/auth/user_info').then((data) => {
      setUserData(data);
    });
  };
  const loadMenu = () => {
    setVisible(true);
    request
      .get<Menu[]>('/menu/list')
      .then((data) => {
        const menuArr1: React.SetStateAction<Menu[]> = [];
        const menuArr2: React.SetStateAction<Menu[]> = [];
        data.forEach((item) => {
          // 判断是否包含某个字符串
          if (item.type === 'wscx') {
            menuArr1.push(item);
          } else if (item.type === 'ywbl') {
            menuArr2.push(item);
          }
        });
        setMenu1(menuArr1);
        setMenu2(menuArr2);
      })
      .finally(() => setVisible(false));
  };

  useEffect(() => {
    const loadData = async () => {
      await loadUser();
      await loadMenu();
    };
    loadData();
  }, []);

  return (
    <div className={'wrap'}>
      <div style={{ fontSize: 12 }}>
        <Card
          title={
            <div style={{ borderLeft: '3px solid #1677ff', paddingLeft: 10 }}>
              <h3 style={{ margin: 0, padding: 0 }}>政民互动</h3>
            </div>
          }
        >
          <Grid columns={4} gap={6}>
            <Grid.Item>
              <Link className="link" to="/me">
                <div className={'menu-item'}>
                  <div>
                    <div className={'menu-item-icon'}>
                      <img className={'menu-item-icon-img'} src={user} alt="" />
                    </div>
                    <div>我的</div>
                  </div>
                </div>
              </Link>
            </Grid.Item>
            {menu1.map((item, index) => {
              return (
                <Grid.Item key={index}>
                  {
                    // 判断是否包含某个字符串
                    // if (item.name.indexOf('一证一码') !== -1) {
                    item.path.indexOf('http') !== -1 ? (
                      <a
                        href={`${item.path}?username=${userData?.name}&idcard=${userData?.idCard}`}
                        className={'link'}
                      >
                        <div className={'menu-item'}>
                          <div>
                            <div className={'menu-item-icon'}>
                              <img
                                className={'menu-item-icon-img'}
                                src={item.icon}
                                alt=""
                              />
                            </div>
                            <div>{item.name}</div>
                            {item.name === '一证一码' ? (
                              <div>分户图扫一扫</div>
                            ) : null}
                          </div>
                        </div>
                      </a>
                    ) : (
                      <Link className="link" to={item.path}>
                        <div className={'menu-item'}>
                          <div>
                            <div className={'menu-item-icon'}>
                              <img
                                className={'menu-item-icon-img'}
                                src={item.icon}
                                alt=""
                              />
                            </div>
                            <div>{item.name}</div>
                            {item.name === '一证一码' ? (
                              <div>分户图扫一扫</div>
                            ) : null}
                          </div>
                        </div>
                      </Link>
                    )
                  }
                </Grid.Item>
              );
            })}
          </Grid>
        </Card>
        <Card
          style={{ marginTop: 10 }}
          title={
            <div style={{ borderLeft: '3px solid #1677ff', paddingLeft: 10 }}>
              <h3 style={{ margin: 0, padding: 0 }}>业务办理</h3>
            </div>
          }
        >
          <Grid columns={4} gap={6}>
            {menu2.map((item, index) => {
              return (
                <Grid.Item key={index}>
                  {
                    // 判断是否包含某个字符串
                    // if (item.name.indexOf('一证一码') !== -1) {
                    item.path.indexOf('http') !== -1 ? (
                      <a
                        href={`${item.path}?username=${userData?.name}&idcard=${userData?.idCard}`}
                        className={'link'}
                      >
                        <div className={'menu-item'}>
                          <div>
                            <div className={'menu-item-icon'}>
                              <img
                                className={'menu-item-icon-img'}
                                src={item.icon}
                                alt=""
                              />
                            </div>
                            <div>{item.name}</div>
                            {item.name === '一证一码' ? (
                              <div>分户图扫一扫</div>
                            ) : null}
                          </div>
                        </div>
                      </a>
                    ) : (
                      <Link className="link" to={item.path}>
                        <div className={'menu-item'}>
                          <div>
                            <div className={'menu-item-icon'}>
                              <img
                                className={'menu-item-icon-img'}
                                src={item.icon}
                                alt=""
                              />
                            </div>
                            <div>{item.name}</div>
                            {item.name === '一证一码' ? (
                              <div>分户图扫一扫</div>
                            ) : null}
                          </div>
                        </div>
                      </Link>
                    )
                  }
                </Grid.Item>
              );
            })}
          </Grid>
        </Card>
      </div>
      <Mask
        color="white"
        visible={visible}
        onMaskClick={() => setVisible(false)}
      />
    </div>
  );
}
