import React from 'react';
import { Button, Dialog, Form, Input } from 'antd-mobile';

function Pay() {
  const onFinish = (values: any) => {
    window.location.href = `https://dzbdcdj.cn/pay-app/?ywlsh=${values.ywh}&xzqdm=511700`;
  };
  return (
    <div>
      <Form
        style={{ marginTop: 40 }}
        onFinish={onFinish}
        layout="horizontal"
        footer={
          <Button block type="submit" color="primary" size="large">
            支付
          </Button>
        }
      >
        <Form.Header>支付</Form.Header>
        <Form.Item
          name="ywh"
          label="业务号"
          rules={[{ required: true, message: '业务号不能为空' }]}
        >
          <Input onChange={console.log} placeholder="请输入业号" />
        </Form.Item>
      </Form>
    </div>
  );
}

export default Pay;
