import React, { useEffect } from 'react';
import { Dialog, Swiper, Toast } from 'antd-mobile';

import '../css/Home.css';
import Menu from './MenuItem';
import bg1 from '../img/bg1.png';
import bg2 from '../img/bg2.png';
import bg3 from '../img/bg3.png';
import { request } from '../util/request';
import { UserResponse } from './PersonalCenter';

export default function Home() {
  // useEffect(() => {
  //   // 延时获取用户信息
  //   setTimeout(() => {
  //     request.get<UserResponse>('/auth/user_info').then(async (data) => {
  //       if (data == null) {
  //         const result = await Dialog.confirm({
  //           content: `未注册，是否前往注册？`,
  //         });
  //         if (result) {
  //           window.location.href = '/#/me';
  //         }
  //       } else if (data.faceAuth !== 'yes') {
  //         const result = await Dialog.confirm({
  //           content: `未进行实名认证，是否前往认证？`,
  //         });
  //         if (result) {
  //           window.location.href = '/#/me';
  //         }
  //       } else {
  //         localStorage.setItem('user', JSON.stringify(data));
  //       }
  //     });
  //   }, 1000);
  // }, []);
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        backgroundColor: '#f0f0f0',
        overflowY: 'scroll',
      }}
    >
      <Swiper autoplay>
        <Swiper.Item key={1}>
          <div style={{ padding: 6 }}>
            <img style={{ width: '100%' }} src={bg1} />
          </div>
        </Swiper.Item>
        <Swiper.Item key={2}>
          <div style={{ padding: 6 }}>
            <img style={{ width: '100%' }} src={bg2} />
          </div>
        </Swiper.Item>
        <Swiper.Item key={3}>
          <div style={{ padding: 6 }}>
            <img style={{ width: '100%' }} src={bg3} />
          </div>
        </Swiper.Item>
      </Swiper>
      <div style={{ padding: 6 }}>
        <Menu />
      </div>
    </div>
  );
}
